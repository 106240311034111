import { faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons';
import { NgForOf, NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import type { Banner, Chain } from '@dextools/blockchains';
import { LocalStorageUtil } from '@dextools/utils';
import { BannersService, LOCAL_STORAGE_CHALLENGE_BANNER_VIEWED } from '../../services/banners.service';

@Component({
  selector: 'app-challenge-banners',
  templateUrl: './challenge-banners.component.html',
  styleUrls: ['./challenge-banners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FontAwesomeModule, NgForOf, NgIf, TranslateModule],
})
export class ChallengeBannersComponent implements OnInit {
  protected readonly icons = {
    faArrowRight,
    faClose,
  };

  @Input()
  public chain: Chain | null;

  protected selectedChallenge: Banner | null;

  private readonly _destroyRef = inject(DestroyRef);

  public constructor(
    private readonly _bannersService: BannersService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    // noop
  }

  public ngOnInit() {
    this._bannersService.challengeBanners
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap((banners) => {
          this._checkChallengesAndSelect(banners);
        }),
      )
      .subscribe();
  }

  protected goToTarget(link: string, id: string) {
    this.closeAndSave(id);
    window.open(link, '_blank', 'noopener');
  }

  protected closeAndSave(challengeId: string) {
    LocalStorageUtil.addToMap(LOCAL_STORAGE_CHALLENGE_BANNER_VIEWED, challengeId, true);
    this.selectedChallenge = null;
  }

  private _checkChallengesAndSelect(challenges: Banner[]) {
    // we need to check if the banners are active in the selected chain or are enable for all app,
    // also we need to check localStorage to see if user has already viewed the message
    const validChallenges: Banner[] = [];
    for (const challenge of challenges) {
      if ((!this.chain || challenge.chains.includes(this.chain)) && this._isNewChallenge(challenge._id)) {
        validChallenges.push(challenge);
      }
    }
    //  We show the banner with the closes start date
    const recentChallenges = this._getChallengesWithClosestStart(validChallenges);
    // IMPORTANT: if there are two or more, we select only one of them randomly
    this.selectedChallenge = recentChallenges[Math.floor(Math.random() * recentChallenges.length)];
    this._cdRef.detectChanges();
  }

  private _isNewChallenge(id: string) {
    const savedChallengeIds = LocalStorageUtil.getMap(LOCAL_STORAGE_CHALLENGE_BANNER_VIEWED);
    return !savedChallengeIds.get(id);
  }

  private _getChallengesWithClosestStart(challenges: Banner[]) {
    return challenges.filter((challenge) => {
      return challenges.some((ch) => {
        return new Date(challenge.startDate).getTime() >= new Date(ch.startDate).getTime();
      });
    });
  }
}
