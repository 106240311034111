@if (selectedChallenge) {
  <div class="p-3 challenge-container">
    <img [src]="selectedChallenge.files.smallImage" [alt]="selectedChallenge.name + ' challenge image'" class="mb-2" />
    <div class="challenge-buttons">
      <button type="button" class="btn btn-primary" (click)="goToTarget(selectedChallenge.targetLink ?? '', selectedChallenge._id)">
        <fa-icon [icon]="icons.faArrowRight" class="me-1"></fa-icon>
        <span>{{ selectedChallenge.description }}</span>
      </button>
      <button type="button" class="btn button-close" (click)="closeAndSave(selectedChallenge._id)">
        <fa-icon [icon]="icons.faClose" class="me-1"></fa-icon>
        <span>{{ 'home.close' | translate }}</span>
      </button>
    </div>
  </div>
}
