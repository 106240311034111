import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { AuthenticationService, SettingsService } from '@dextools/core';
import { BundleCommunicationService } from '../services/bundle-communication/bundle-communication.service';
import { ExchangeService } from '@dextools/blockchains/services';

export const BUNDLE_COMMUNICATION_SERVICE = new InjectionToken<BundleCommunicationService | null>('BundleCommunicationService', {
  factory: () => {
    const platformId = inject(PLATFORM_ID);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (isPlatformBrowser(platformId) && window.Dextools) {
      const settingsService = inject(SettingsService);
      const authenticationService = inject(AuthenticationService);
      const exchangeService = inject(ExchangeService);
      const router = inject(Router);
      return new BundleCommunicationService(settingsService, authenticationService, exchangeService, router);
    }
    return null;
  },
});
