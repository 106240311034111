import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { forkJoin, of, switchMap, tap } from 'rxjs';
import type { RegisterDevice } from '@dextools/core';
import {
  APP_MOBILE,
  AuthenticationService,
  CaptchaService,
  CryptoUtil,
  LOCALSTORAGE,
  Platform as DevicePlatform,
  SyncService,
} from '@dextools/core';
import { LocalStorageUtil, UrlUtil } from '@dextools/utils';
import { AnalyticsService } from '@dextools/analytics';
import { BannersService } from './banners/services/banners.service';
import { ChainWebSocketsService } from '@dextools/blockchains/services';

const OLD_TOKEN = 'dataAnyone';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _captchaService: CaptchaService,
    private readonly _syncService: SyncService,
    private readonly _webSocketService: ChainWebSocketsService,
    private readonly _bannerService: BannersService,
    private readonly _analyticsService: AnalyticsService,
  ) {
    // noop
  }

  public init(fullUrl: URL): Observable<unknown> {
    // execute these tasks "in parallel" for faster startup (just do some stuff in the meantime some Http requests return data)
    return forkJoin([
      // Authentication-related tasks
      CryptoUtil.loadCryptoLibs().pipe(
        switchMap(() => {
          // IMPORTANT: crypto libs are used by Authentication Service!
          this._authenticationService.initialize();
          this._syncService.initialize();
          return this._registerDevice(fullUrl);
        }),
        tap(() => {
          this._prepareLogin();
          this._bannerService.initialize(); // because HttpTokenInterceptor intercepts banners calls
        }),
      ),
      // NON-authentication-related tasks
      of(true).pipe(
        tap(() => {
          this._captchaService.initialize();
          this._webSocketService.initialize();
          if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] !== 'local') {
            // IMPORTANT: the analytics script should be loaded before calling any code of the Analytics provider (Matomo)
            this._analyticsService.loadScript().then(() => this._analyticsService.initialize());
          }
        }),
      ),
    ]);
  }

  private _prepareLogin() {
    if (this._authenticationService.authToken != null) {
      return;
    }
    let token: string | null;

    // get logged userData from storage
    const data = JSON.parse(LocalStorageUtil.getString(LOCALSTORAGE.USER_DATA) as string);

    if (data) {
      // get logged token
      token = data.jwt;
      if (token) {
        // remove jwt token from userData
        delete data.jwt;
        LocalStorageUtil.setString(LOCALSTORAGE.USER_DATA, JSON.stringify(data));
      } else {
        // remove user data (corrupt data)
        this._authenticationService.logout();
      }
    } else {
      // get anyone token
      token = LocalStorageUtil.getString(OLD_TOKEN);
    }

    if (token) {
      // place new token
      this._authenticationService.authToken = token;
    }

    // remove old token
    LocalStorageUtil.delete(OLD_TOKEN);
  }

  private _registerDevice(fullUrl: URL): Observable<unknown> {
    const isMobileApp = this._authenticationService.isMobileApp;
    const deviceId = fullUrl.searchParams.get(APP_MOBILE.DEVICE_ID);

    if (!isMobileApp) {
      // DeviceId can only be set in the mobile app
      this._authenticationService.removeDeviceInfo();
      if (deviceId !== null) {
        this._removeDeviceParams(fullUrl);
      }
      return of('noop');
    }

    const platForm = (fullUrl.searchParams.get(APP_MOBILE.PLATFORM) ?? '').toLowerCase() as DevicePlatform;
    if (!!deviceId && !!platForm && Object.values(DevicePlatform).includes(platForm)) {
      const params: RegisterDevice = { deviceId, platform: platForm };
      return this._authenticationService.registerDevice(params).pipe(tap(() => this._removeDeviceParams(fullUrl)));
    }

    return of('noop');
  }

  private _removeDeviceParams(fullUrl: URL) {
    // IMPORTANT: delete the device-related search params from the URL so that they are not included in any link shared from the app!
    const urlWithoutQueryParams = UrlUtil.removeQueryParams(fullUrl, [APP_MOBILE.DEVICE_ID, APP_MOBILE.PLATFORM]);
    // IMPORTANT: using location.replace() here so that the user won't be able to use the Back button to navigate to the original URL
    window.location.replace(urlWithoutQueryParams.toString());
  }
}
