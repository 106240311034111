import { NgModule } from '@angular/core';
/**
 * ICONS
 */
import {
  arrowDownIcon,
  arrowUpIcon,
  bigSwapIcon,
  CustomIconsService,
  gemIcon,
  liveNewPairsIcon,
  mintIcon,
  moreIcon,
  multichartIcon,
  multiswapIcon,
  newPairsBotIcon,
  nftIcon,
  pairExplorerIcon,
  priceBotIcon,
  repeatIcon,
  statsIcon,
  updateIcon,
  variationDownIcon,
  variationUpIcon,
  walletInfoIcon,
} from '@dextools/icons';

// TODO: migrate to a standalone app format (no NgModules anymore!)
@NgModule()
export class IconsModule {
  public constructor(customIconsService: CustomIconsService) {
    // OWN CUSTOM icons
    customIconsService.addIcons([
      arrowDownIcon,
      arrowUpIcon,
      bigSwapIcon,
      gemIcon,
      liveNewPairsIcon,
      mintIcon,
      moreIcon,
      multichartIcon,
      multiswapIcon,
      newPairsBotIcon,
      nftIcon,
      pairExplorerIcon,
      priceBotIcon,
      repeatIcon,
      statsIcon,
      updateIcon,
      variationDownIcon,
      variationUpIcon,
      walletInfoIcon,
    ]);
  }
}
