import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService, Language, SettingsService } from '@dextools/core';
import type { DextoolsConfig, DextoolsEvent, Payload } from '@dextools/puppet';
import { filter } from 'rxjs';
import type { DextoolsAppConfig } from '../../models/config.model';
import { ExchangeService } from '@dextools/blockchains/services';

type DextoolsWindowConfigObject = NonNullable<typeof window.Dextools>;

@Injectable({
  providedIn: 'root',
})
export class BundleCommunicationService {
  private _basicConfig: Partial<DextoolsConfig> = {};
  public constructor(
    private readonly _settingsService: SettingsService<DextoolsAppConfig>,
    private readonly _authenticationService: AuthenticationService,
    private readonly _exchangeService: ExchangeService,
    private readonly _router: Router,
  ) {
    this._router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event) => {
      this.emitGlobalEvent('PageLoaded', { page: event.urlAfterRedirects, chain: this._exchangeService.chain });
    });
  }

  public initialize(): void {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (window.Dextools) {
      this._setupEventListeners(window.Dextools);
      this._setInitialConfig(window.Dextools);
    }
  }

  /**
   * Sets up the event listeners for the dexTools events that are going to be executed when dextools emits them.
   * The events are defined in the {@link DextoolsEvent} enum.
   *
   * @param windowConfigObj - The config object defined by the Puppet library under `window.Dextools`.
   */
  private _setupEventListeners(windowConfigObj: DextoolsWindowConfigObject): void {
    if (windowConfigObj.callbacks) {
      const callbacks = windowConfigObj.callbacks;
      for (const eventName in callbacks) {
        window.addEventListener(eventName, (event) => {
          const callback = callbacks[eventName as keyof typeof callbacks];
          if (typeof callback === 'function') {
            const payload = (event as CustomEvent<{ payload: Payload<DextoolsEvent> }>).detail.payload;
            callback(payload as never);
          }
        });
      }
    }
  }

  /**
   * Sets the initial config for the bundle.
   *
   * @param windowConfigObj - The config object defined by the Puppet library under `window.Dextools`.
   */
  private _setInitialConfig(windowConfigObj: DextoolsWindowConfigObject): void {
    const { language, dark_theme } = this._settingsService.getFullConfig();
    this._basicConfig = {
      language,
      dark_theme,
      userPlan: this._authenticationService.getCurrentUserValue()?.plan,
    };
    windowConfigObj.config = this._basicConfig;
    windowConfigObj.setConfig = (config) => {
      this._setConfig(config, windowConfigObj);
    };
  }

  /**
   * Sets the config for the bundle. This function is available in the window object to be called from the bundle.
   *
   * @param config - The config to set.
   * @param windowConfigObj - The config object defined by the Puppet library under `window.Dextools`.
   */
  private _setConfig(config: Partial<DextoolsConfig>, windowConfigObj: DextoolsWindowConfigObject): void {
    this._basicConfig = { ...this._basicConfig, ...config };
    windowConfigObj.config = this._basicConfig;

    const tempConfig: Partial<DextoolsAppConfig> = {};

    if (this._basicConfig.language != null) {
      tempConfig.language = this._basicConfig.language as Language;
    }
    if (this._basicConfig.dark_theme != null) {
      tempConfig.dark_theme = this._basicConfig.dark_theme;
    }

    this._settingsService.setTemporalConfig(tempConfig);
  }

  /**
   * Emits an event globally.
   *
   * @param eventName - The name of the event to emit.
   * @param payload - The payload of the event.
   */
  public emitGlobalEvent(eventName: DextoolsEvent, payload?: Payload<DextoolsEvent>): void {
    window.dispatchEvent(
      new CustomEvent(eventName, {
        detail: {
          payload,
        },
      }),
    );
  }
}
