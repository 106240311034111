import type { ProcessLinkData } from '@dextools/analytics';

export const PROCESS_LINKS_LIST: ProcessLinkData[] = [
  {
    id: 'connectWallet',
    processName: 'Connect Wallet',
    steps: [
      { processStep: 'Paso 1 - Click connect' },
      { processStep: 'Paso 2 - Wallet conectada' },
      { processStep: 'Paso 3 - Click verificar' },
      { processStep: 'Paso 4 - Finalizado' },
    ],
  },
];
