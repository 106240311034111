@if (isUpdateAvailable && !o.activatedRouteData['isEmptyPage']) {
  <div class="alert alert-dismissible full-width-alert text-center alert-primary fade show mb-0">
    <fa-icon [icon]="icons.faInfo" class="mx-2"></fa-icon>
    New app version available.
    <span *rxLet="forcedUpdateTimeout$; let countdown"
      >It will be automatically updated in {{ countdown }} {{ countdown > 1 ? 'mins' : 'min' }} or
    </span>
    <a href="javascript:" type="button" class="upgrade-action text-white" (click)="updateApp()"> Click here to update</a>
  </div>
}

@if (isWsConnectionUnstable && !o.activatedRouteData['isEmptyPage']) {
  <div class="alert alert-dismissible full-width-alert text-center alert-custom-warning fade show mb-0">
    <fa-icon [icon]="icons.faExclamationTriangle" class="mx-2"></fa-icon>
    {{ 'app.real_time_issues_part_1' | translate }}
    <a href="javascript:" type="button" class="upgrade-action text-white" (click)="reloadApp()">
      {{ 'app.real_time_issues_part_2' | translate }}</a
    >
    {{ 'app.real_time_issues_part_3' | translate }}
  </div>
}

<div class="container-fluid icon-sidebar-nav p-0" appInterestLink appProcessLink>
  <div class="row">
    @if (!o.activatedRouteData['isEmptyPage']) {
      <app-side-menu></app-side-menu>
    }
    <main [@fadeAnimation]="o.isActivated && !o.activatedRouteData['disableAnimation'] ? o.activatedRoute : ''">
      <router-outlet #o="outlet"></router-outlet>
    </main>
  </div>
</div>
@if (!showVideoModal) {
  <app-video-button
    [ngClass]="{ 'align-with-footer': bottomReached }"
    [link]="link"
    [showModal]="showAcademyModal"
    buttonImage="assets/img/logo/dextforce-academy.jpeg"
    videoPreviewImage="assets/img/video-home.webp"
    (modalClosed)="closeAcademyModal()"
  ></app-video-button>
}

<app-announcement [chain]="chain"></app-announcement>
<app-challenge-banners [chain]="chain"></app-challenge-banners>
