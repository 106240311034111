import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, filter, map, timer } from 'rxjs';
import type { Chain } from '@dextools/blockchains';
import { chainList } from '@dextools/blockchains';
import { ApiService, PathId } from '@dextools/core';
import { HOUR_IN_MILLIS, LocalStorageUtil, MINUTE_IN_MILLIS } from '@dextools/utils';
import type { Announcement, ApiAnnouncementData } from '../../models/announcement.model';

const LOCALSTORAGE_ANNOUNCEMENTS_KEY = 'announcements';
const LOCALSTORAGE_ANNOUNCEMENTS_CHECK_DATE_KEY = 'lastAnnouncementsCheck';
const ANNOUNCEMENTS_EXPIRATION_TIME_MILLIS = HOUR_IN_MILLIS; // 1 hour
const ANNOUNCEMENTS_INTERVAL_CHECK = 5 * MINUTE_IN_MILLIS; // 5 minutes

export interface AnnouncementWithStatus extends Announcement {
  alreadyViewed: boolean;
}

export interface AnnouncementWithStatusByChain {
  [chain: string]: AnnouncementWithStatus | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class AnnouncementsService {
  private _announcementsByChain: AnnouncementWithStatusByChain = {};
  private readonly _announcementsByChain$ = new BehaviorSubject<AnnouncementWithStatusByChain | null>(this._announcementsByChain);

  public constructor(private readonly _apiService: ApiService) {
    // noop
  }

  public get isAnnouncementListExpired(): boolean {
    const lastCheckDate = LocalStorageUtil.getDate(LOCALSTORAGE_ANNOUNCEMENTS_CHECK_DATE_KEY);
    return lastCheckDate ? Date.now() - ANNOUNCEMENTS_EXPIRATION_TIME_MILLIS > lastCheckDate.getTime() : true;
  }

  public get announcementsFromAllChains$(): Observable<AnnouncementWithStatusByChain | null> {
    return this._announcementsByChain$.asObservable();
  }

  public get announcementsCall(): Observable<ApiAnnouncementData> {
    const pathRouteFilter = `?product=announcement`;
    return this._apiService
      .get<{ data: ApiAnnouncementData }>(PathId.BACK, `/banner${pathRouteFilter}`)
      .pipe(map((response) => response?.data ?? []));
  }

  /**
   * Initializes the cache of announcements and fetches them from API if necessary.
   *
   * IMPORTANT:
   * 1- Should be called only once (during application startup).
   * 2- There can be only one announcement active per chain (for UX only 1 announcement at a time is enough).
   */
  public initialize() {
    this.initializeAllAnnouncements();

    // fetch immediately and every X minutes
    timer(0, ANNOUNCEMENTS_INTERVAL_CHECK).subscribe(() => {
      this._fetchAnnouncements();
    });
  }

  public initializeAllAnnouncements() {
    if (LocalStorageUtil.getString(LOCALSTORAGE_ANNOUNCEMENTS_KEY)) {
      this._updateAnnouncementsInLocalStorage();
    } else {
      LocalStorageUtil.setMap(LOCALSTORAGE_ANNOUNCEMENTS_KEY, new Map<Chain, Announcement>());
    }
  }

  public getAnnouncementsByChain(chain: Chain): Observable<AnnouncementWithStatus> {
    return this._announcementsByChain$.asObservable().pipe(
      filter(
        (announcementsWithStatusByChain): announcementsWithStatusByChain is Required<AnnouncementWithStatusByChain> =>
          announcementsWithStatusByChain?.[chain] != null,
      ),
      map((announcementsWithStatusByChain) => announcementsWithStatusByChain[chain] as AnnouncementWithStatus),
    );
  }

  public isAnnouncementValid(announcement: Announcement | null): boolean {
    if (announcement) {
      const dateNow = new Date();
      return dateNow >= new Date(announcement.startDate) && dateNow <= new Date(announcement.endDate) && !announcement.disabled;
    }
    return false;
  }

  public markAnnouncementAsViewed(announcement: AnnouncementWithStatus) {
    announcement.alreadyViewed = true;
    this._updateAnnouncementsInLocalStorage(announcement);
  }

  private _fetchAnnouncements() {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.announcementsCall.subscribe((announcementList) => {
      let shouldRefreshAnnouncements = false;

      let latestAnnouncementFromApi: Announcement | undefined;

      // IMPORTANT: only the latest 'active' announcement from API is considered
      if (announcementList.length > 0) {
        latestAnnouncementFromApi = announcementList
          .filter((item) => !item.disabled)
          .sort((a, b) => Date.parse(b.startDate) - Date.parse(a.startDate))[0];
      }

      if (this.isAnnouncementListExpired) {
        shouldRefreshAnnouncements = true;
      } else if (latestAnnouncementFromApi) {
        // if API returns announcements, we must confirm they are different from the ones we already have
        const currentAnnouncements = [...this._getStoredAnnouncements().values()];
        const newAnnouncements: Announcement[] = [];

        // create as many items as chains are in the announcement (or in all chains if the announcement has no chains defined)
        const chainsArray = latestAnnouncementFromApi.chain.length > 0 ? latestAnnouncementFromApi.chain : chainList;
        for (let idx = 0; idx < chainsArray.length; idx++) {
          newAnnouncements.push(latestAnnouncementFromApi);
        }

        shouldRefreshAnnouncements = this._areNewAnnouncementsDifferent(currentAnnouncements, newAnnouncements);
      }

      if (shouldRefreshAnnouncements && latestAnnouncementFromApi) {
        if (!LocalStorageUtil.getString(LOCALSTORAGE_ANNOUNCEMENTS_KEY)) {
          LocalStorageUtil.setMap(LOCALSTORAGE_ANNOUNCEMENTS_KEY, new Map<Chain, Announcement>());
          this._announcementsByChain = {};
        }

        const announcementWithStatus: AnnouncementWithStatus = { ...latestAnnouncementFromApi, alreadyViewed: false };

        // announcement for specific chains
        if (announcementWithStatus.chain.length > 0) {
          for (const chain of announcementWithStatus.chain) {
            this._setAnnouncementData(chain, announcementWithStatus);
          }
        } else {
          // announcement for all chains
          for (const chain of chainList) {
            this._setAnnouncementData(chain, announcementWithStatus);
          }
        }

        LocalStorageUtil.setDate(LOCALSTORAGE_ANNOUNCEMENTS_CHECK_DATE_KEY);
        this._announcementsByChain$.next(this._announcementsByChain);
      }
    });
  }

  private _getStoredAnnouncements(): Map<string, AnnouncementWithStatus> {
    return LocalStorageUtil.getMap<AnnouncementWithStatus>(LOCALSTORAGE_ANNOUNCEMENTS_KEY);
  }

  private _areNewAnnouncementsDifferent(currentItems: Announcement[], newItems: Announcement[]): boolean {
    if (currentItems.length !== newItems.length) {
      return true;
    }

    for (const newItem of newItems) {
      const currentItem = currentItems.find((item) => item._id === newItem._id);
      if (
        !currentItem ||
        currentItem.startDate !== newItem.startDate ||
        currentItem.endDate !== newItem.endDate ||
        currentItem.disabled !== newItem.disabled ||
        currentItem.chain.length !== newItem.chain.length ||
        currentItem.contentHtml !== newItem.contentHtml ||
        currentItem.titleHtml !== newItem.titleHtml ||
        currentItem.name !== newItem.name
      ) {
        return true;
      }
    }

    return false;
  }

  private _setAnnouncementData(chain: string, announcement: AnnouncementWithStatus) {
    if (
      !this._announcementsByChain ||
      Object.keys(this._announcementsByChain).length === 0 ||
      !this._announcementsByChain[chain] ||
      (!!this._announcementsByChain[chain] &&
        this._announcementsByChain[chain]?._id !== announcement._id &&
        this.isAnnouncementValid(announcement))
    ) {
      LocalStorageUtil.addToMap(LOCALSTORAGE_ANNOUNCEMENTS_KEY, chain, announcement);
      // IMPORTANT: we only keep one announcement per chain
      this._announcementsByChain[chain] = announcement;
    }
  }

  private _updateAnnouncementsInLocalStorage(announcement?: AnnouncementWithStatus) {
    const announcementsInLocalStorage = this._getStoredAnnouncements();

    for (const chain of announcementsInLocalStorage.keys()) {
      if (!!announcement && (announcementsInLocalStorage.get(chain) as AnnouncementWithStatus)._id === announcement._id) {
        announcementsInLocalStorage.set(chain, announcement);
      }

      this._announcementsByChain[chain] = announcementsInLocalStorage.get(chain) as AnnouncementWithStatus;
    }

    if (announcement) {
      LocalStorageUtil.mergeToMap(LOCALSTORAGE_ANNOUNCEMENTS_KEY, announcementsInLocalStorage);
      this._announcementsByChain$.next(this._announcementsByChain);
    }
  }
}
