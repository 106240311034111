import type { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import type { Environment } from '@dextools/core';

/**
 * Translate loader factory.
 *
 * @param http - Angular's HttpClient
 * @param environment - Current environment
 * @returns the HttpLoader to use by TranslateModule
 */
export function HttpLoaderFactory(http: HttpClient, environment: Environment) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cb=${environment.appVersion.toLowerCase().split(' ').join('.')}`);
}
