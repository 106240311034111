import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { defer, take } from 'rxjs';
import { catchError, of, tap } from 'rxjs';
import { Chain, ChainUtil } from '@dextools/blockchains';
import { RoutingService } from '@dextools/core';
import { PairsApiService, ExchangeService } from '@dextools/blockchains/services';
import type { Alias } from '@dextools/blockchains';

export const aliasResolver: ResolveFn<Alias | null> = (route: ActivatedRouteSnapshot) => {
  const pairsApiService = inject(PairsApiService);
  const routingService = inject(RoutingService);
  const exchangeService = inject(ExchangeService);

  return defer(() => {
    const aliasInUrl: string = route.params['alias'];
    if (!aliasInUrl) {
      return of(null);
    }

    return pairsApiService.getAllAliasData(aliasInUrl, false, false).pipe(
      catchError(() => of(null)),
      tap((pairData) => {
        // Alias no exist
        if (pairData === null) {
          console.warn(`The alias does not exist. (${aliasInUrl})`);
          routingService.navigate(`/${Chain.Ethereum}/pair-explorer/${ChainUtil.getDefaultPairByChain(Chain.Ethereum)}`);
          return;
        }

        exchangeService.chain = pairData.chain;
      }),
    );
  }).pipe(
    take(1), // important so that this observable completes and the router can continue!
  );
};
