import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { WalletStore } from './store/wallet.store';
import type { WalletConfig } from './models/wallet-config.model';
import { walletConfigProviderFactory } from './models/wallet-config.model';

@NgModule({})
export class SolanaWalletAdapterModule {
  public static forRoot(walletConfig: Partial<WalletConfig>): ModuleWithProviders<SolanaWalletAdapterModule> {
    return {
      ngModule: SolanaWalletAdapterModule,
      providers: [walletConfigProviderFactory(walletConfig), WalletStore],
    };
  }
}
