@if (announcement) {
  <div class="banner-announcement">
    <button type="button" class="close" (click)="closeAnnouncement()">
      <span><fa-icon [icon]="icons.faTimes"></fa-icon></span
    ></button>
    <div class="banner-announcement-body">
      <fa-icon [icon]="icons.faBullhorn"></fa-icon>
      <div class="banner-announcement-content">
        <div [innerHTML]="announcementTitle"></div>
        <div [innerHTML]="announcementContent"></div>
      </div>
    </div>
  </div>
}
