/*eslint-disable rxjs/no-nested-subscribe*/
/*eslint-disable @typescript-eslint/no-unnecessary-condition*/
//TODO: remove unnecessary conditions.
import { faExclamationTriangle, faInfo } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT, Location, NgClass } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import {
  afterNextRender,
  ApplicationInitStatus,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  Inject,
  Injector,
} from '@angular/core';
import { GuardsCheckEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RxLet } from '@rx-angular/template/let';
import type { Observable, Subscription } from 'rxjs';
import {
  bufferTime,
  combineLatest,
  delay,
  filter,
  from,
  fromEvent,
  interval,
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { AggregatorUtil } from '@dextools/aggregator';
import { InterestLinkDirective, ProcessLinkDirective } from '@dextools/analytics';
import { Chain, ChainUtil, TypeListSEO } from '@dextools/blockchains';
import type { Language, User } from '@dextools/core';
import {
  ACCOUNT_CHECK_INTERVAL,
  AppUpdateService,
  AuthenticationService,
  LazyInject,
  LOCALSTORAGE,
  NotificationsService,
  PageLifeCycleService,
  PAID_ROLES,
  Role,
  SettingsService,
} from '@dextools/core';
import { BUNDLE_COMMUNICATION_SERVICE } from './shared/models/bundle-communication.model';
import { DeviceService } from '@dextools/ui-utils';
import { fadeAnimation } from '@dextools/ui-utils/animations';
import { CommonUtil, IntersectionObserverUtil, LocalStorageUtil } from '@dextools/utils';
import { ChallengeBannersComponent } from './banners/components/challenge-banner/challenge-banners.component';
import { BannersService } from './banners/services/banners.service';
import type { ChallengeService } from './challenge/services/challenge/challenge.service';
import { SideMenuComponent } from './layout/components/side-menu/side-menu.component';
import { AnnouncementComponent } from './shared/components/announcement/announcement.component';
import { AppPage } from './shared/models/app-page.model';
import type { DextoolsAppConfig } from './shared/models/config.model';
import { AnnouncementsService } from './shared/services/announcements/announcements.service';
import type { BundleCommunicationService } from './shared/services/bundle-communication/bundle-communication.service';
import type { DextoolsSettingsService } from './shared/services/settings/settings.service';
import { AppPageUtil } from './shared/utils/app-pages.util';
import { SIMULATOR_ID } from './simulator/constants/simulator.constants';
import type { SimulatorService } from './simulator/services/simulator.service';
import { CoinzillaService } from './shared/services/coinzilla/coinzilla.service';
import { VideoButtonComponent } from '@dextools/ui/components';
import {
  ChainWebSocketsService,
  CommonService,
  ExchangeService,
  FavoritePairsV2Service,
  PriceAlertsService,
  PriceTrackService,
  TokenLogosService,
} from '@dextools/blockchains/services';

// A warning will be shown in case the number of WebSocket errors in the last WS_ERRORS_BUFFER_MILLIS is WS_ERRORS_THRESHOLD or higher
const WS_ERRORS_BUFFER_MILLIS = 4_000;
const WS_ERRORS_THRESHOLD = 4;

// Youtube DEXTools Academy
const LOCAL_STORAGE_ACADEMY = 'academy';
const LOCAL_STORAGE_ACADEMY_MODAL_VIEWED = 'academyModalViewed';
const LOCAL_STORAGE_ACADEMY_VIDEO_VERSION = 'videoVersion';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AnnouncementComponent,
    ChallengeBannersComponent,
    FontAwesomeModule,
    InterestLinkDirective,
    NgClass,
    ProcessLinkDirective,
    RouterOutlet,
    RxLet,
    SideMenuComponent,
    TranslateModule,
    VideoButtonComponent,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  protected readonly icons = {
    faInfo,
    faExclamationTriangle,
  };

  private _init_charge = true;
  private _timeout: Subscription;
  protected isUpdateAvailable = false;
  protected isWsConnectionUnstable = false;
  protected forcedUpdateTimeout$: Observable<number> | null;

  private _previousChain: Chain | null | undefined; // IMPORTANT: 'null' and 'undefined' are treated differently in this page. See constructor
  private _previousLanguage: Language;

  protected bottomReached = false;
  protected chain = this._exchangeService.chain ?? Chain.Ethereum;

  private _userAccess = false;
  private _walletLogged: User | null = null;
  private _intersectionObserverSubs: Subscription;
  private readonly _routerNavigationEnd$ = this._router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
  );

  /**
   * DextTools academy
   * The modal will be shown again when the app starts in case the 'videoVersion' has changed
   */
  protected link: string;
  private readonly _videoVersion = '01';
  protected showVideoModal = false;
  protected showAcademyModal = false;

  private _challengeService: InstanceType<typeof ChallengeService>;
  private _simulatorService: InstanceType<typeof SimulatorService>;

  public constructor(
    @Inject(DOCUMENT) document: Document,
    private readonly _authenticationService: AuthenticationService,
    private readonly _notificationsService: NotificationsService<DextoolsAppConfig>,
    private readonly _priceTrackService: PriceTrackService,
    private readonly _priceAlertsService: PriceAlertsService,
    private readonly _router: Router,
    private readonly _appUpdates: AppUpdateService,
    private readonly _exchangeService: ExchangeService,
    private readonly _settingsService: SettingsService<DextoolsAppConfig>,
    private readonly _announcementsService: AnnouncementsService,
    private readonly _modalService: NgbModal,
    private readonly _webSocketService: ChainWebSocketsService,
    private readonly _commonService: CommonService,
    private readonly _tokenLogosService: TokenLogosService,
    private readonly _appInitStatus: ApplicationInitStatus,
    private readonly _location: Location,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _bannerService: BannersService,
    private readonly _lazyInject: LazyInject,
    private readonly _injector: Injector,
    private readonly _pageLifeCycleService: PageLifeCycleService,
    private readonly _deviceService: DeviceService,
    private readonly _favoritePairsServiceV2: FavoritePairsV2Service,
    private readonly _coinzillaService: CoinzillaService,
    @Inject(BUNDLE_COMMUNICATION_SERVICE) private readonly _bundleCommunication: BundleCommunicationService | null,
  ) {
    this._settingsService.initialize(document);

    this._tokenLogosService.initialize();

    afterNextRender({
      read: () => {
        this._commonService.initialize(this.chain); // TODO: this uses WebSocket, we need an Http variant for SSR!

        this._bundleCommunication?.initialize();

        this._pageLifeCycleService.initialize();

        effect(
          () => {
            if (this._pageLifeCycleService.activeAgainSignal()) {
              location.reload();
            }
          },
          { injector: this._injector },
        );

        fromEvent(window, 'beforeunload')
          .pipe(take(1))
          .subscribe(() => {
            this._priceAlertsService.stopAlerts();
            this._webSocketService.closeConnection();
          });

        if (IntersectionObserverUtil.isIntersectionObserverSupported(window)) {
          // IMPORTANT: here we rely on the Router's navigation end events.
          // We cannot rely on the Router Outlet's activateEvents because those are not triggered when navigating between "nested" routes!
          // This is the case of Live New Pairs, Pair Explorer and Big Swap Explorer, which are children routes (see ExchangesRoutingModule)
          this._routerNavigationEnd$
            .pipe(
              delay(1_000), // to ensure the footer is already rendered
            )
            .subscribe(() => {
              const footerElement = document.querySelector('footer.main-footer');

              if (footerElement != null) {
                // threshold 0 makes the intersection callback to be fired as soon as 1 pixel of the element is
                // visible so the user doesn't really need to scroll entirely to the bottom edge of the page
                const options: IntersectionObserverInit = { threshold: 0 };

                if (this._intersectionObserverSubs) {
                  this._intersectionObserverSubs.unsubscribe();
                }

                this._intersectionObserverSubs = IntersectionObserverUtil.observeElementIntersection(footerElement, options)
                  .pipe(filter(([entry]: IntersectionObserverEntry[]) => entry != null))
                  .subscribe(([entry]: IntersectionObserverEntry[]) => {
                    this._handleScrollingBottomReached(entry);
                  });
              }
            });
        }

        this._bundleCommunication?.emitGlobalEvent('DextoolsLoaded');
      },
    });

    this._deviceService.initialize();

    this._notificationsService.initialize();

    this._coinzillaService.initialize();

    combineLatest([
      this._settingsService.getConfigChanged$('language'),
      this._router.events.pipe(filter((event): event is GuardsCheckEnd => event instanceof GuardsCheckEnd)),
    ])
      .pipe(take(1))
      .subscribe((values: [string, GuardsCheckEnd]) => {
        this.link = 'https://www.youtube.com/embed/UHfteIKUJfA';
        this.showVideoModal = this._shouldShowYoutubeVideo(values[1].urlAfterRedirects);
        if (this.showVideoModal) {
          this._openVideoYT();
        }
        this._cdRef.detectChanges();
      });

    this._favoritePairsServiceV2.initialize();

    this._authenticationService.currentUser$
      .pipe(
        tap((userData) => {
          const userAccess = !!userData && PAID_ROLES.includes(userData.plan);
          if (userAccess !== this._userAccess) {
            this._userAccess = userAccess;

            if (!this._init_charge) {
              this._priceAlertsService.initializeAlerts().subscribe();
            }
          }
          if (this._walletLogged == null || this._walletLogged.id !== userData?.id) {
            this._checkActiveChallenge(userData);
            this._checkUserSimulator(userData);
          }
        }),
      )
      .subscribe();

    // only the first banner should be fetched/loaded with a delay (to improve initial load performance of the app)
    this._bannerService.setBannerInitialDelay(1_000);
    from(this._appInitStatus.donePromise)
      .pipe(delay(1_500), take(1))
      .subscribe(() => {
        // any new banner will be fetched/loaded with no delay
        this._bannerService.removeBannerInitialDelay();
      });

    this._appUpdates.initialize();

    // IMPORTANT: display the warning in case the number of errors in the last WS_ERRORS_BUFFER_MILLIS is WS_ERRORS_THRESHOLD or higher
    combineLatest([
      this._webSocketService.isConnected$(),
      this._webSocketService.errors$().pipe(
        bufferTime(WS_ERRORS_BUFFER_MILLIS),
        filter((errors) => errors.length > 0),
      ),
    ]).subscribe(([isConnected, errors]) => {
      if (!this.isWsConnectionUnstable && errors.length >= WS_ERRORS_THRESHOLD && !isConnected) {
        this.isWsConnectionUnstable = true;
        this._cdRef.detectChanges();
      }
    });

    this._announcementsService.initialize();

    // Subscribe to notifications when newer versions of the app version are available
    this._appUpdates.updateAvailable$.subscribe((event) => {
      if (event.forcedUpdateTimeout) {
        const forcedUpdateTimeout = event.forcedUpdateTimeout;
        // countdown in minutes
        this.forcedUpdateTimeout$ = interval(1_000 * 60).pipe(
          take(forcedUpdateTimeout),
          startWith(-1),
          map((value) => forcedUpdateTimeout - value - 1), // -1 due to the delay of the message to appear
          tap((value) => {
            if (value === 0) {
              this.updateApp();
            }
          }),
        );
      }
      this.isUpdateAvailable = true;
      this._cdRef.detectChanges();
    });

    // We need to wait for any Router navigation to finish so that we use the final url in the comparisons done here
    // in order to correctly determine whether we trigger a reload or not
    combineLatest([this._exchangeService.chain$, this._routerNavigationEnd$, this._settingsService.getConfigChanged$('language')])
      .pipe(
        tap((values: [Chain | null, NavigationEnd, Language]) => {
          // if it's not the initial load (no chain selected -> previousChain === undefined)
          if (this._previousChain === undefined || !!this._router.getCurrentNavigation()) {
            this._previousChain = values[0]; // tu be used afterwards, see below
          }

          if (!this._previousLanguage) {
            this._previousLanguage = this._settingsService.language;
          }
          // The language changes without navigation, so we have to have the latest language updated
          if (values[0] === this._previousChain && this._router.getCurrentNavigation() != null) {
            this._previousLanguage = values[2];
          }
          this._cdRef.detectChanges();
        }),
        // IMPORTANT: we only care about chain changes when navigation ends (we discard it while there is navigation in progress)
        filter((values: [Chain | null, NavigationEnd, Language]) => {
          return values[0] !== this._previousChain && this._router.getCurrentNavigation() == null;
        }),
      )
      .subscribe((values: [Chain | null, NavigationEnd, Language]) => {
        this.chain = values[0] ?? Chain.Ethereum;

        let currentUrl = this._router.url;

        const queryParamsRegex = '(#.*|\\?.*)?$';

        // IMPORTANT: in case the URL has been changed programmatically by calling `location.go()` then we'll take that one instead of the one from the router.
        // Also the language should be taken from the settings service because no real navigation has happened yet!
        if (this._router.url !== this._location.path()) {
          currentUrl = this._location.path(true);
          this._previousLanguage = this._settingsService.language;
        }

        // reload page to pick the chain change including the url change if needed
        // adapting url in case it is the PairExplorer
        let calculatedUrl = currentUrl.replace(
          new RegExp(`${this._previousLanguage}/${AppPage.Token}/.*`),
          `${this._previousLanguage}/${this.chain}/${AppPage.PairExplorer}/${ChainUtil.getDefaultPairByChain(this.chain)}`,
        );

        calculatedUrl = calculatedUrl.replace(
          new RegExp(`${this._previousLanguage}/${this._previousChain}/${AppPage.PairExplorer}/.*`),
          `${this._settingsService.language}/${this.chain}/${AppPage.PairExplorer}/${ChainUtil.getDefaultPairByChain(this.chain)}`,
        );

        // reload page to pick the chain change including the url change if needed
        // adapting url in case it is the Dextswap
        calculatedUrl = calculatedUrl.replace(
          new RegExp(`${this._previousLanguage}/${this._previousChain}/${AppPage.Dextswap}/.*`),
          `${this._settingsService.language}/${this.chain}/${AppPage.Dextswap}/${AggregatorUtil.getDefaultTokenByNetwork(
            AggregatorUtil.getNetworkByChain(this.chain),
          )}`,
        );

        // adapting url in case it is the PoolExplorer, Multiswap, WalletInfo, BigSwaps or Stats
        calculatedUrl = calculatedUrl.replace(
          new RegExp(
            `${this._previousLanguage}/${this._previousChain}/(${AppPage.LiveNewPairs}|${AppPage.Multiswap}|${AppPage.WalletInfo}|${AppPage.Stats}|${AppPage.BaseFun}|${AppPage.BigSwap})(/.*)?${queryParamsRegex}`,
          ),
          `/${this._settingsService.language}/${this.chain}/$1$2`,
        );

        // Adapting URL in case there is a category selected
        calculatedUrl = calculatedUrl.replace(
          // Regular expression to match the previous language and optional previous chain, followed by the pair list
          new RegExp(`${this._previousLanguage}(?:/${this._previousChain})?/(${TypeListSEO.pairList})(/.*)?`),
          // Replacement pattern to update the language and chain in the URL
          values[0] // As we save the chain as Ether when is null, we need to check when its null really.
            ? `/${this._settingsService.language}/${this.chain}/$1$2`
            : `/${this._settingsService.language}/$1$2`,
        );

        // adapting url in case it is the Homepage
        const pairListsTypes = CommonUtil.convertEnumToArray(TypeListSEO).join('|');
        const pairListsTypesRegex = new RegExp(pairListsTypes);
        const currentUrlPairListTypeMatch = calculatedUrl.match(pairListsTypesRegex);
        const chainPath = this._previousChain ? `/${this._previousChain}` : '';

        const replaceUrlPrefix = `${AppPage.Dashboard}${this._settingsService.language}`;
        const replaceUrlSuffix = currentUrlPairListTypeMatch ? `/${currentUrlPairListTypeMatch[0]}` : '';
        const replaceUrlChain = values[0] ? `/${this.chain}` : '';

        calculatedUrl = calculatedUrl.replace(
          new RegExp(
            `/${this._previousLanguage}((${chainPath}(/(${pairListsTypes}))?)?${queryParamsRegex})`, // the whole string should match
          ),
          `${replaceUrlPrefix}${replaceUrlChain}${replaceUrlSuffix}`,
        );

        this._previousChain = values[0];
        this._previousLanguage = this._settingsService.language;
        this._cdRef.detectChanges();

        // reload only if the calculated url is different
        if (currentUrl !== calculatedUrl) {
          this._router.navigate([calculatedUrl]);
        }
      });
  }

  public ngOnInit() {
    // When the user is logged in we check his DEXT every ACCOUNT_CHECK_INTERVAL, if he doesn't have any, he is logged out
    this._authenticationService.currentUser$.subscribe((data_user) => {
      if (this._timeout) {
        this._timeout.unsubscribe();
      }

      // The service is called either if he is a user, it is the initial load or the current date is later than the time of the last check
      if (data_user?.ts) {
        let date_diff = false;
        if (Date.now() - data_user.ts >= ACCOUNT_CHECK_INTERVAL) {
          date_diff = true;
        }

        if (this._init_charge && date_diff) {
          this._getTokensDextMethod(data_user.id);
        }
        this._timeout = timer(ACCOUNT_CHECK_INTERVAL).subscribe(() => {
          this._getTokensDextMethod(data_user.id);
        });
      }
      this._cdRef.detectChanges();

      if (this._init_charge) {
        this._priceAlertsService.priceAlertsIssued$
          .pipe(
            map((priceAlerts) => this._priceAlertsService.getNotificationsForAlerts(priceAlerts, AppPage.PairExplorer)),
            tap((priceAlertNotifications) => {
              this._notificationsService.notify(priceAlertNotifications, 0);
            }),
          )
          .subscribe();
      }

      const lastRole = LocalStorageUtil.getString('lastRole') || Role.P0;
      // Check alerts status of user
      if (data_user && PAID_ROLES.includes(data_user.plan) && (this._init_charge || lastRole !== data_user.plan)) {
        this._priceAlertsService
          .getAlertRecipients()
          .pipe(
            tap((activeAlerts) => {
              if (activeAlerts.email) {
                this._priceAlertsService.setAlertStatus({ email: true });
              }
              if (!!activeAlerts.telegramBotId && !!activeAlerts.telegramBotUrl && !!activeAlerts.telegramUserKey) {
                this._priceAlertsService.setAlertStatus({ telegram: true });
              }
            }),
          )
          .subscribe();
      }
      LocalStorageUtil.setString('lastRole', data_user?.plan ?? '');
    });

    this._priceTrackService.historyLoaded$
      .pipe(
        filter((isLoaded) => !!isLoaded),
        take(1),
        mergeMap(() => this._priceAlertsService.initializeAlerts()),
      )
      .subscribe();

    (this._settingsService as DextoolsSettingsService).showAcademyModal$.subscribe((showAcademyModal) => {
      if (showAcademyModal) {
        const youtubeAcademyURL = 'https://www.youtube.com/c/DEXToolsAcademy';
        window.open(youtubeAcademyURL, '_blank', 'noopener');
        if (!LocalStorageUtil.getMap(LOCAL_STORAGE_ACADEMY).get(LOCAL_STORAGE_ACADEMY_MODAL_VIEWED)) {
          LocalStorageUtil.addToMap(LOCAL_STORAGE_ACADEMY, LOCAL_STORAGE_ACADEMY_MODAL_VIEWED, true);
          this.showAcademyModal = showAcademyModal;
        }
      } else {
        this.showAcademyModal = false;
      }
      this._cdRef.markForCheck();
    });
  }

  public ngAfterViewInit() {
    this._init_charge = false;
    this._cdRef.detectChanges();
  }

  private _getTokensDextMethod(userId: string) {
    this._authenticationService.getTokensDext(userId).subscribe((loginData) => {
      if (loginData.data) {
        LocalStorageUtil.setString(LOCALSTORAGE.USER_DATA, JSON.stringify({ data: loginData.data }));
        this._authenticationService.setCurrentUserValue(this._authenticationService.decryptDataUser());
      }
    });
  }

  protected updateApp() {
    this._appUpdates.updateApp();
  }

  protected reloadApp() {
    location.reload();
  }

  private _handleScrollingBottomReached(entry: IntersectionObserverEntry) {
    this.bottomReached = entry.isIntersecting;
    this._cdRef.detectChanges();
  }

  private _openVideoYT() {
    from(import('@dextools/ui/components'))
      .pipe(
        switchMap((module) => {
          const videoYTModal = this._modalService.open(module.VideoYTModalComponent, {
            centered: true,
            scrollable: true,
          });
          videoYTModal.componentInstance.link = this.link;
          return from(videoYTModal.result);
        }),
      )
      .subscribe({
        next: () => {
          LocalStorageUtil.addToMap(LOCAL_STORAGE_ACADEMY, LOCAL_STORAGE_ACADEMY_VIDEO_VERSION, this._videoVersion);
          this.showVideoModal = false;
          this._cdRef.detectChanges();
        },
        error: () => {
          LocalStorageUtil.addToMap(LOCAL_STORAGE_ACADEMY, LOCAL_STORAGE_ACADEMY_VIDEO_VERSION, this._videoVersion);
          this.showVideoModal = false;
          this._cdRef.detectChanges();
        },
      });
  }

  private _shouldShowYoutubeVideo(currentUrl: string): boolean {
    if (AppPageUtil.getAppPageFromUrl(currentUrl) !== AppPage.Dashboard) {
      return false;
    }

    const lastVideo = LocalStorageUtil.getMap<string>(LOCAL_STORAGE_ACADEMY).get(LOCAL_STORAGE_ACADEMY_VIDEO_VERSION) ?? null;

    return lastVideo !== this._videoVersion;
  }

  protected closeAcademyModal() {
    this.showAcademyModal = false;
    (this._settingsService as DextoolsSettingsService).showAcademyModal(this.showAcademyModal);
    this._cdRef.detectChanges();
  }

  private async _checkActiveChallenge(userData: User | null) {
    return; // TODO- DELETE WHEN THERE IS A NEW CHALLENGE
    this._challengeService = await this._lazyInject.get<ChallengeService>(() =>
      import('./challenge/services/challenge/challenge.service').then((module) => module.ChallengeService),
    );
    this._walletLogged = userData;
    this._challengeService.checkActiveChallenge(userData).then();
  }

  private async _checkUserSimulator(userData: User | null) {
    this._simulatorService = await this._lazyInject.get<SimulatorService>(() =>
      import('./simulator/services/simulator.service').then((module) => module.SimulatorService),
    );
    if (this._simulatorService.simulatorEnabled) {
      userData?.id
        ? this._simulatorService.validateUser(userData.id, SIMULATOR_ID).pipe(take(1)).subscribe()
        : this._simulatorService.clearUserSimulator();
    }
  }
}
