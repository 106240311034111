import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { take } from 'rxjs';
import { blacklistedWindowNames, environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { APP_CONFIG } from './app/app.config';

/**
 * Whether the app is embedded (refreshers, etc.)
 *
 * @param appUrl - The app url
 * @param whitelistedUrls - The whitelisted urls
 * @param isPrPreview - Whether the current build is for a PR preview
 *
 * @returns boolean `true` if the app is not embedded, `false` otherwise
 */
export function isAppRunningInValidWindow(appUrl: string, whitelistedUrls: string[], isPrPreview = false): boolean {
  // add a backlash '\' before every special character in the url ($& means the whole matched string)
  const parsedAppUrl = appUrl.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&');

  // add a backlash '\' before every special character in the url ($& means the whole matched string)
  // IMPORTANT: here we don't include '|' character on these replacements to enable regex concatenation afterwards
  const parsedWhiteListedUrls = whitelistedUrls.join('|').replace(/[$()*+./?[\\\]^{}-]/g, '\\$&');

  try {
    const parentWindowUrl = window.parent.location.href;
    const isSameWindow = parentWindowUrl.match(`^${parsedAppUrl}`) != null;
    const isParentWindowWhitelisted = whitelistedUrls.length > 0 && parentWindowUrl.match(`^(${parsedWhiteListedUrls})`) != null;

    return (isSameWindow || isParentWindowWhitelisted || isPrPreview) && !blacklistedWindowNames.includes(window.name);
  } catch {
    // If we can't access the parent window, it means we are in a different domain
    return false;
  }
}

/**
 * Loads all modules that should NOT be imported in production.
 *
 * For example: Scully.
 *
 * Taken from: https://www.wittyprogramming.dev/articles/lazy-load-component-angular-without-routing/
 */
export async function loadNonProductionModules(): Promise<void> {
  return; // noop
}

if (environment.production) {
  enableProdMode();
}

let isPrPreview = false;

if (process.env['NX_PUBLIC_APP_ENVIRONMENT'] === 'pr-preview') {
  isPrPreview = true;
}

if (isAppRunningInValidWindow(environment.appUrl, environment.whitelistedUrls, isPrPreview)) {
  bootstrapApplication(AppComponent, APP_CONFIG)
    .then((appRef) => {
      appRef.isStable.pipe(take(2)).subscribe((isStable) => {
        console.log('App stable:', isStable);
      });

      loadNonProductionModules().then();
    })
    // eslint-disable-next-line unicorn/prefer-top-level-await
    .catch((error) => {
      console.error(error);
    });
}
