import { Location } from '@angular/common';
import { Injectable, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LanguageUtil, SeoService, SettingsService } from '@dextools/core';
import type { DextoolsAppConfig } from '../../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class DextoolsSettingsService extends SettingsService<DextoolsAppConfig> {
  public readonly _defaultConfigValue: Partial<DextoolsAppConfig> = {
    language: LanguageUtil.supportNavigationLanguage, // the default browser's language if that matched our supported languages
    hot_movement: false, // means hots movement is enabled
    candle_normalization: true,
    fill_candles_gaps: true,
    dark_theme: true,
    notifications: false,
    notificationsLogoUrl: 'assets/img/logo/dextools_logo.png',
    notificationsAudioUrl: 'assets/sounds/notification.mp3',
    simulatorEnabled: true,
    challengeEnabled: true,
  };
  public readonly _localStorageConfigKey = 'configApp';

  private readonly _showAcademyModal$ = new BehaviorSubject<Partial<boolean>>(false);
  public showAcademyModal$ = this._showAcademyModal$.asObservable();

  public constructor(_location: Location, _translate: TranslateService, _seoService: SeoService, _rendererFactory: RendererFactory2) {
    super(_location, _translate, _seoService, _rendererFactory);
  }

  public showAcademyModal(show: boolean) {
    this._showAcademyModal$.next(show);
  }
}
